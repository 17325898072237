<script setup>

import {useRouter} from "@/routes";
import {useStore} from "@/store/store";
import {onMounted, ref} from "vue";

const store = useStore()
const router = useRouter()

const buttons = [
  {
    text: "Einbuchen",
    flow: "einbuchen",
    icon: "add.svg",
    color: "primary"
  },
  {
    text: "Umlagern",
    flow: "umlagern",
    icon: "umlagern.svg",
    color: "secondary",
  },
  {
    text: "Picken",
    flow: "picken",
    icon: "trolley.svg",
    color: "primary",
  },
  {
    text: "Abfrage",
    flow: "abfrage",
    icon: "abfrage.svg",
    color: "secondary"
  },
]

const ordersCount = ref()

function startFlow(newFlow) {
  store.commit("setActualFlow", newFlow)
  router.push(newFlow.flow)
}

onMounted(() => {
  store.dispatch("loadOrderCount").then(function (res) {
    const ordersCountResponse = res.data
    if (ordersCountResponse.code === "Ok") {
      ordersCount.value = ordersCountResponse.data
    }
  })
})

</script>

<template>
  <v-main>
    <!-- todo vue3 fill height not working-->
    <v-container fluid fill-height>
      <v-row
          v-for="(button, idx) in buttons"
          :key="idx"
          :align="'center'">
        <v-col
            cols="3"
            sm="2"
            :align="'center'">
          <a :href="button.link">
            <v-img
                alt="Logo"
                class="shrink mr-2"
                contain
                :src="require('../assets/' + button.icon)"
                width="100"
            />
          </a>
        </v-col>
        <v-col :justify="'start'">
          <v-btn
              :height="100"
              x-large
              block
              :color="button.color"
              @click="startFlow(button)">
            {{ button.text }}

            <v-btn v-if="button.flow === 'picken' && ordersCount && ordersCount > 0"
                   class="secondary font-weight-bold text-h6"
                   absolute
                   right
                   small>
              <span class="pendingOrderCounter">{{ ordersCount }}</span>
            </v-btn>

          </v-btn>

        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style scoped>
.pendingOrderCounter {
  color: #1B2046;
}
</style>