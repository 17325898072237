import Vue from 'vue'
import VueRouter from "vue-router";
import HomeComponent from "@/components/Home";
import NewHome from "@/components/NewHome";
import Login from "@/components/Login";
import EANEntry from "@/components/EANEntry";
import ProductEdit from "@/components/ProductEdit";
import ProductCreation from "@/components/ProductCreation";
import axios from "@/plugins/axios";
import OrdersOverview from "@/components/OrdersOverview";
import ItemPick from "@/components/ItemPick";
import PriceChange from "@/components/PriceChange";
import DispatchOverview from "@/components/DispatchOverview.vue";
import EANLabelCreation from "@/components/EANLabelCreation.vue";
import NewProductEntry from "@/components/NewProductEntry.vue";
import ProductStorageLocation from "@/components/ProductStorageLocation.vue";
import ProductQuery from "@/components/ProductQuery.vue";
import ProductQueryEAN from "@/components/ProductQueryEAN.vue";
import ProductQueryLocation from "@/components/ProductQueryLocation.vue";
import StorageLocationChangeAuslagern from "@/components/StorageLocationChangeAuslagern.vue";
import StorageLocationChangeEinlagern from "@/components/StorageLocationChangeEinlagern.vue";
import StorageLocationChange from "@/components/StorageLocationChange.vue";
import OrdersOverviewV2 from "@/components/OrdersOverviewV2.vue";
import ItemPickV2 from "@/components/ItemPickV2.vue";


Vue.use(VueRouter);

const routes = [
    { path: '/homealt', component: HomeComponent },
    { path: '/home', component: NewHome },
    { path: '/login', component: Login },
    { path: '/scan', component: EANEntry },
    { path: '/neu/:ean', component: ProductCreation },
    { path: '/ausbuchen', component: EANEntry },
    { path: '/ausbuchen/:ean', component: ProductEdit },
    { path: '/einbuchenalt', component: EANEntry },
    { path: '/einbuchenalt/:ean', component: ProductEdit },
    { path: '/einbuchen', component: NewProductEntry },
    { path: '/einbuchen/:ean', component: ProductStorageLocation },
    { path: '/umlagern', component: StorageLocationChange },
    { path: '/auslagern', component: StorageLocationChangeAuslagern },
    { path: '/einlagern', component: StorageLocationChangeEinlagern },
    { path: '/retoureEinlagern', component: StorageLocationChangeEinlagern },
    { path: '/abfrage', component: ProductQuery },
    { path: '/abfrage/produkt/:ean', component: ProductQueryEAN },
    { path: '/abfrage/lagerplatz/:location', component: ProductQueryLocation },
    { path: '/pickenalt', component: OrdersOverview },
    { path: '/pickenalt/:orderNumber', component: ItemPick },
    { path: '/picken', component: OrdersOverviewV2 },
    { path: '/picken/:orderNumber', component: ItemPickV2 },
    { path: '/preis', component: EANEntry },
    { path: '/preis/:ean', component: PriceChange },
    { path: '/dashboard', component: DispatchOverview },
    { path: '/label', component: EANLabelCreation },
    { path: '/', redirect: '/home' }
];

const router =  new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
});

router.beforeEach(function (to, from, next) {
    if (to.path !== '/login' && to.path !== "/scantest") {
        axios
            .get("/api/login/loggedIn", {withCredentials: true})
            .then(() => next())
            .catch(() => next({path: '/login'}))
    } else next()
})

export default router;
export const useRouter = () => router