import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios'
import backendInterface from "@/util/interface";
import util from "@/util/util";

Vue.use(Vuex)

let store = new Vuex.Store({
    state: {
        ean: "",
        actualFlow: {
            flow: "",
            text: "",
            icon: ""
        },
        loading: false,
        product: {},
        alertDialog: {
            visible: false,
            text: "",
            type: "",
            timeout: null
        },
        confirmDialog: {
            visible: false,
            text: "",
            buttonText: "",
            cancelButtonText: null,
            cancelButtonColor: null,
            type: "error",
            timeout: null,
            callback: null
        },
        loggedIn: null,
        lastPriceUsed: null,
        pickingItem: {
            order: null,
            item: null
        },
        pagedOrders: {
            orders: []
        },
        selectedSellingPlatform: "Alle",
        selectedShippingProviders: null,
        ordersActualPage: 1,
        dispatchOverview: {
            entries: []
        },
        actualProductQuantity: 1,
        quantityChangeDialog: {
            visible: false,
            text: "",
            type: "error",
            timeout: null,
        },
        productsWithStorageLocations: [],
        tempStorageLocation: null,
        returnStorageLocation: null,
        quantityWarningDialog: {
            visible: false,
            text: "",
            type: "error",
            timeout: null,
            callback: () => {
            },
            cancelCallback: () => {
            }
        },
    },
    mutations: {
        setEan(state, newEan) {
            if (process.env.VUE_APP_DEBUG_MODE) console.log("setEan")
            state.ean = newEan
        },
        setActualFlow(state, newFlow) {
            state.actualFlow = newFlow
        },
        setLoading(state, newValue) {
            state.loading = newValue
        },
        setProduct(state, product) {
            state.product = product
        },
        showAlertDialog(state, {type, text, time}) {
            state.alertDialog.visible = true
            state.alertDialog.text = text
            state.alertDialog.type = type
            if (state.alertDialog.timeout) {
                clearTimeout(state.alertDialog.timeout)
            }

            state.alertDialog.timeout = setTimeout(function () {
                state.alertDialog.visible = false
                state.alertDialog.text = ""
            }, time)
        },
        hideAlertDialog(state) {
            state.alertDialog.visible = false
            state.alertDialog.text = ""
        },
        showConfirmDialog(state, {
            type,
            text,
            buttonText,
            time,
            callback,
            cancelButtonText,
            cancelButtonColor,
            cancelButtonCallback
        }) {
            state.confirmDialog.visible = true
            state.confirmDialog.text = text
            state.confirmDialog.type = type
            state.confirmDialog.callback = callback
            state.confirmDialog.buttonText = buttonText
            state.confirmDialog.cancelButtonText = cancelButtonText
            state.confirmDialog.cancelButtonColor = cancelButtonColor
            state.confirmDialog.cancelButtonCallback = cancelButtonCallback
            if (state.confirmDialog.timeout) {
                clearTimeout(state.confirmDialog.timeout)
            }

            if (time && time > 0) {
                state.confirmDialog.timeout = setTimeout(function () {
                    state.confirmDialog.visible = false
                    state.confirmDialog.text = ""
                }, time)
            }
        },
        hideConfirmDialog(state) {
            state.confirmDialog.visible = false
            state.confirmDialog.text = ""
        },
        setNewProductData(state, {stock, price, newSku, minPrice}) {
            state.product.data.stock = stock
            state.product.data.price = price
            state.product.data.minPrice = minPrice
            state.product.data.sku = newSku
        },
        setLastPriceUsed(state, price) {
            state.lastPriceUsed = price
        },
        setOrders(state, orders) {
            state.pagedOrders = orders
        },
        setPickingItem(state, orderItem) {
            state.pickingItem = orderItem
        },
        setSelectedSellingPlatform(state, sellingPlatform) {
            state.selectedSellingPlatform = sellingPlatform
        },
        setSelectedShippingProviders(state, newSelectedShippingProviders) {
            state.selectedShippingProviders = newSelectedShippingProviders
        },
        setOrdersActualPage(state, newPage) {
            state.ordersActualPage = newPage
        },
        setDispatchOverview(state, dispatchOverview) {
            state.dispatchOverview = dispatchOverview
        },
        openQuantityChangeDialog(state) {
            state.quantityChangeDialog.visible = true
        },
        hideQuantityChangeDialog(state) {
            state.quantityChangeDialog.visible = false
        },
        setProductsWithStorageLocations(state, productsWithStorageLocations) {
            state.productsWithStorageLocations = productsWithStorageLocations
        },
        setTempStorage(state, storageLocation) {
            state.tempStorageLocation = storageLocation
        },
        setReturnStorage(state, storageLocation) {
            state.returnStorageLocation = storageLocation
        },
        openQuantityWarningDialog(state, {text, callback, cancelCallback}) {
            state.quantityWarningDialog.visible = true
            state.quantityWarningDialog.text = text
            state.quantityWarningDialog.callback = callback
            state.quantityWarningDialog.cancelCallback = cancelCallback
        },
        hideQuantityWarningDialog(state) {
            state.quantityWarningDialog.visible = false
            state.quantityWarningDialog.text = ""
            state.quantityWarningDialog.callback = () => {
            }
            state.quantityWarningDialog.cancelCallback = () => {
            }

        },
        setActualProductQuantity(state, newQuantity) {
            state.actualProductQuantity = newQuantity
        }
    },
    actions: {
        loadOrderCount() {
            return axios.get("/api/v2/orders/count")
        },
        showError({commit}, {text, time}) {
            const type = 'error'
            commit('hideAlertDialog')
            commit('showAlertDialog', {type, text, time})
        },
        showInfo({commit}, {text, time}) {
            const type = 'info'
            commit('hideAlertDialog')
            commit('showAlertDialog', {type, text, time})
        },
        showErrorConfirm({commit}, {
            text,
            buttonText,
            time,
            callback,
            cancelButtonText,
            cancelButtonColor,
            cancelButtonCallback
        }) {
            const type = 'error'
            commit('hideConfirmDialog')
            commit('showConfirmDialog', {
                type,
                text,
                buttonText,
                time,
                callback,
                cancelButtonText,
                cancelButtonColor,
                cancelButtonCallback
            })
        },
        showInfoConfirm({commit}, {
            text,
            buttonText,
            time,
            callback,
            cancelButtonText,
            cancelButtonColor,
            cancelButtonCallback
        }) {
            const type = 'info'
            commit('hideConfirmDialog')
            commit('showConfirmDialog', {
                type,
                text,
                buttonText,
                time,
                callback,
                cancelButtonText,
                cancelButtonColor,
                cancelButtonCallback
            })
        },
        login({commit}, {username, password}) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                const request = {
                    username: username,
                    password: password
                }

                axios
                    .post("/api/login", request)
                    .then(function (response) {
                        let authenticationResponse = response.data;
                        localStorage.setItem("lang.authentication.jwtToken", authenticationResponse.jwtToken)
                        resolve()
                    })
                    .catch(function () {
                        self.dispatch('showError', {text: "Fehler beim Login", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false));
            })
        },
        loadProduct({commit}, ean) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("loadProduct" + ean)
            const self = this;
            const billbeeOnly = this.state.actualFlow.flow === "ausbuchen"
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/products/" + ean + "?billbeeOnly=" + billbeeOnly, {withCredentials: true})
                    .then(function (response) {
                        const productResponse = response.data
                        if (productResponse.code === backendInterface.codes.ProductNotFound) {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (productResponse.code === backendInterface.codes.TechnicalError) {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        } else {
                            commit('setProduct', productResponse)
                            resolve(productResponse)
                        }

                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadOrders({commit}, {actualPage, sellingPlatform}) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                let url = "/api/orders?actualPage=" + actualPage + "&sellingPlatform=" + sellingPlatform;
                axios
                    .get(url, {withCredentials: true})
                    .then(function (response) {
                        const orders = response.data
                        commit('setOrders', orders)
                        resolve(orders)
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadDispatchOverview({commit}) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/orders/dispatchOverview", {withCredentials: true})
                    .then(function (response) {
                        const dispatchOverviewResponse = response.data
                        if (dispatchOverviewResponse.code === "Ok") {
                            commit('setDispatchOverview', dispatchOverviewResponse.data)
                            resolve(dispatchOverviewResponse)
                        } else {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        }
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        pickItem({commit}) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("pickItem")
            const self = this;
            return new Promise((resolve, reject) => {
                const pickingItem = self.state.pickingItem;
                axios
                    .post("/api/orders/" + pickingItem.order.billBeeOrderId + "/pickItem/" + pickingItem.item.billbeeId + "?sellingPlatform=" + pickingItem.order.sellingPlatform, {withCredentials: true})
                    .then(function (response) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log("Successfully picked item")
                        const pickItemResponse = response.data
                        resolve(pickItemResponse)
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        createProduct({commit}, {stock, price, newSku, minPrice}) {
            commit('setLoading', true)
            commit('setNewProductData', {stock, price, newSku, minPrice})
            const self = this;
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/products", self.state.product.data, {withCredentials: true})
                    .then(function () {
                        self.dispatch('showInfo', {text: "Das Angebot wurde erfolgreich erstellt", time: 1000})
                        resolve()
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        createLabelAndPrint({commit}, {ean, quantity}) {
            commit('setLoading', true)
            const self = this
            const url = "/api/products/createLabelAndPrint?ean=" + ean + "&quantity=" + quantity
            return new Promise((resolve, reject) => {
                axios.post(url)
                    .then(function (response) {
                        const createLabelResponse = response.data
                        if (createLabelResponse.code === "Ok") {
                            self.dispatch('showInfo', {text: "Die Labels wurden erfolgreich erstellt", time: 1000})
                            resolve()
                        } else if (createLabelResponse.code === backendInterface.codes.ProductNotFound) {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (createLabelResponse.code === "TechnicalError") {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        }
                    })
                    .catch(function () {
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        updateStock({commit}, newStock) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                let url = "/api/products/" + self.state.product.data.sku + "/updateStock/" + newStock;
                axios
                    .post(url, this.state.product.data, {withCredentials: true})
                    .then(function () {
                        self.dispatch('showInfo', {text: "Lagerbestand geändert", time: 1000})
                        resolve()
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        changePrice({commit}, {newPrice, newSalePrice}) {
            commit('setLoading', true)
            const self = this;
            let changePriceRequest = {}
            if (newPrice) changePriceRequest.newPrice = newPrice;
            if (newSalePrice) changePriceRequest.newSalePrice = newSalePrice;


            return new Promise((resolve, reject) => {
                let url = "/api/products/" + self.state.product.data.sku + "/changePrice";
                axios
                    .post(url, changePriceRequest, {withCredentials: true})
                    .then(function () {
                        self.dispatch('showInfo', {
                            text: "Preisänderung wurde vorgemerkt<br/><b>Hinweis</b>: Lagerbestand wurde nicht geändert!",
                            time: 3000
                        })
                        resolve()
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadProductV2({commit}, ean) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("loadProduct" + ean)
            const self = this;
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/v2/products/" + ean, {withCredentials: true})
                    .then(function (response) {
                        const productResponse = response.data
                        if (productResponse.code === backendInterface.codes.ProductNotFound) {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (productResponse.code === backendInterface.codes.TechnicalError) {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        } else {
                            commit('setProduct', productResponse.data)
                            resolve(productResponse)
                        }

                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        enterStorageLocation({commit}, {storageCoordinates, modifyBillbee}) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("enterStorageLocation" + storageCoordinates)
            const self = this;
            const request = {
                storageLocation: {
                    corridor: storageCoordinates.corridor,
                    shelf: storageCoordinates.shelf,
                    box: storageCoordinates.box
                },
                quantityChange: self.state.actualProductQuantity,
                productId: self.state.product.productId
            }

            return new Promise((resolve, reject) => {
                let url = "/api/v2/products/modifyProductStorageLocation?modifyBillbee=" + modifyBillbee;
                axios
                    .post(url, request, {withCredentials: true})
                    .then(function (response) {
                        const productResponse = response.data
                        if (productResponse.code === backendInterface.codes.ProductNotFound) {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (productResponse.code === backendInterface.codes.TechnicalError) {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        } else {
                            resolve(productResponse)
                        }

                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        queryStorageLocation({commit}, storageCoordinates) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("queryStorageLocation" + storageCoordinates)
            const self = this;

            return new Promise((resolve, reject) => {
                axios
                    .post("/api/v2/products/query/location", storageCoordinates, {withCredentials: true})
                    .then(function (response) {
                        const productResponse = response.data
                        if (productResponse.code === backendInterface.codes.ProductNotFound) {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (productResponse.code === backendInterface.codes.TechnicalError) {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        } else {
                            commit('setProductsWithStorageLocations', productResponse.data)
                            resolve(productResponse)
                        }

                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        changeStorageLocation({commit}, request) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("changeStorageLocation", request)
            const self = this;

            return new Promise((resolve, reject) => {
                axios
                    .post("/api/v2/products/changeStorageLocation", request, {withCredentials: true})
                    .then(function (response) {
                        const productResponse = response.data
                        if (productResponse.code === backendInterface.codes.ProductNotFound) {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (productResponse.code === backendInterface.codes.TechnicalError) {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        } else if (productResponse.code === "RequestedQuantityBiggerThanStorage") {
                            let formattedOriginLocation = util.formatStorageLocation(request.originLocation);
                            let formattedDestinationLocation = util.formatStorageLocation(request.destinationLocation);
                            let errorMessage = "<h4>Die Menge am Lagerplatz ist nicht verfügbar</h4>"
                                + util.formatStorageMessage(
                                    request.ean,
                                    formattedOriginLocation,
                                    request.quantityChange,
                                    formattedDestinationLocation
                                )
                            self.dispatch('showErrorConfirm', {
                                text: errorMessage,
                                buttonText: "OK"
                            })
                            reject()
                        } else if (productResponse.code === "ProductNotFoundInLocation") {
                            let formattedOriginLocation = util.formatStorageLocation(request.originLocation);
                            let formattedDestinationLocation = util.formatStorageLocation(request.destinationLocation);
                            const errorMessage = "<h4>Das Produkt ist am Lagerplatz nicht verfügbar</h4>"
                                + util.formatStorageMessage(
                                    request.ean,
                                    formattedOriginLocation,
                                    request.quantityChange,
                                    formattedDestinationLocation
                                )

                            self.dispatch('showErrorConfirm', {
                                text: errorMessage,
                                buttonText: "OK"
                            })
                            reject()
                        } else {
                            resolve(productResponse)
                        }

                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadTempStorage({commit}) {
            return new Promise(() => {
                axios
                    .get("/api/v2/products/specialLocation/TempStorage", {withCredentials: true})
                    .then(function (response) {
                        const storageLocation = response.data
                        commit('setTempStorage', storageLocation)

                    })
                    .catch(function () {
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadReturnStorage({commit}) {
            return new Promise(() => {
                axios
                    .get("/api/v2/products/specialLocation/ReturnStorage", {withCredentials: true})
                    .then(function (response) {
                        const storageLocation = response.data
                        commit('setReturnStorage', storageLocation)

                    })
                    .catch(function () {
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadOrdersV2({commit}, {actualPage, sellingPlatform, shippingProviders}) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                let url = "/api/v2/localOrders?actualPage=" + actualPage + "&sellingPlatform=" + sellingPlatform;
                if (shippingProviders !== null && shippingProviders !== undefined) url += "&shippingProviders=" + shippingProviders;
                axios
                    .get(url, {withCredentials: true})
                    .then(function (response) {
                        const orders = response.data
                        commit('setOrders', orders)
                        resolve(orders)
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        pickItemV2({commit}, request) {
            commit('setLoading', true)
            if (process.env.VUE_APP_DEBUG_MODE) console.log("pickItem")
            const self = this;
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/v2/orders/pickItem", request, {withCredentials: true})
                    .then(function (response) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log("Successfully picked item")
                        const pickItemResponse = response.data
                        resolve(pickItemResponse)
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },


    },
    modules: {}
});
export default store
export const useStore = () => store

