<template>
  <v-main>
    <v-container fill-height>
      <v-form ref="loginForm" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field outlined dense ref="userName" v-model="loginUsername" :rules="requiredRules" label="Nutzername" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined dense ref="password" v-model="loginPassword" :rules="requiredRules" :type="'password'"
                          name="input-10-1" label="Password"></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6" xsm="12">
          </v-col>
          <v-col class="d-flex" cols="12" sm="6" xsm="12" align-end>
            <v-btn color="secondary" large block :disabled="!valid" @click="validate">Login</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "LoginComponent",
  data: () => ({
    valid: false,
    loginUsername: "",
    requiredRules: [
      v => !!v || "Required",
    ],
    loginPassword: "",

  }),
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.$store.dispatch("login", {
          username: this.loginUsername,
          password: this.loginPassword
        }).then(() => this.$router.push("/home"))
      }
    },
  },
  mounted() {
    this.$refs["userName"].focus();
  }
}
</script>

<style scoped>

</style>